@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navlink {
  display: inline-block;
  margin-right: 1em;
  color: white;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-size: large;
  z-index: 10;
  background-color: '#131E3B';
}
.navlink:hover {
  color: #e5c771;
}
.navlink .active {
  color: #e5c771;
}
.heading {
  color: #d8472e;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 42px;
  padding: 1rem 0;
  font-weight: normal;
  margin: 0;
}

.subheading p {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  margin: 0;
}

.subheading .about-us {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  margin: 0;
  text-align: justify;
}

.info-graphic {
  margin: 1rem 0;
}

.get-started {
  margin: 0.5rem 0;
}
.order-now {
  margin: 3rem 0;
}

.purchase-print {
  margin: 3rem 0;
}

.strava-btn {
  margin: 2rem 0;
}

.bg-lt {
  background: url('../public/bckgrnd-img1.png') no-repeat bottom left,
    url('../public/bckgrnd-img2.png') no-repeat bottom right;
  background-size: 380px auto;
}

.product-labels {
  font-size: 24px;
  color: #d8472e;
  font-family: 'Bebas Neue', sans-serif;
  margin: 0;
}

.product-title li {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
}
.product-title {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
}
/* .ant-steps .ant-steps-item .ant-steps-item-container {
  display: flex;
} */
/* .ant-steps .ant-steps-item .ant-steps-item-content {
  display: flex;
  align-items: center;
} */
/* .ant-steps .ant-steps-item .ant-steps-item-content .ant-steps-item-title {
  font-size: 18px;
  white-space: normal;
  line-height: 20px;
  display: none;
} */

/* .ant-steps .ant-steps-item .ant-steps-item-icon {
  display: flex;
  align-items: center;
}
.ant-steps .ant-steps-item .ant-steps-item-icon svg {
  font-size: 25px;
} */

@media screen and (max-width: 1600px) {
  .bg-lt {
    background-size: 340px auto;
  }
}
@media screen and (max-width: 1440px) {
  .bg-lt {
    background-size: 320px auto;
  }
  .heading {
    font-size: 32px;
  }
  .subheading p {
    font-size: 16px;
  }
  .subheading .about-us {
    font-size: 16px;
  }
  .confirmation-label {
    font-size: 32px;
  }
  .product-labels {
    font-size: 32px;
  }
  .product-title {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
  }

  .product-title li {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
  }
  /* .ant-steps .ant-steps-item .ant-steps-item-content .ant-steps-item-title {
    font-size: 16px;
  }
  .ant-steps .ant-steps-item .ant-steps-item-icon svg {
    font-size: 20px;
  } */
}

@media (max-width: 1199px) {
  .bg-lt {
    background-size: 260px auto;
  }
  .heading {
    font-size: 30px;
  }
  .confirmation-label {
    font-size: 30px;
  }
  .product-labels {
    font-size: 30px;
  }
  .product-title {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
  }
  .product-title li {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
  }
  /* .ant-steps .ant-steps-item .ant-steps-item-content .ant-steps-item-title {
    font-size: 15px;
  } */
}

@media (max-width: 991px) {
  .bg-lt {
    background-size: 220px auto;
  }
  .heading {
    font-size: 28px;
  }
  .subheading p {
    font-size: 15px;
  }
  .subheading .about-us {
    font-size: 15px;
  }
  .confirmation-label {
    font-size: 28px;
  }
  .product-labels {
    font-size: 28px;
  }
  .product-title {
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
  }
  .product-title li {
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
  }
}

@media (max-width: 767px) {
  .bg-lt {
    background-size: 120px auto;
  }
  .heading {
    font-size: 24px;
  }
  .subheading p {
    font-size: 14px;
  }
  .subheading .about-us {
    font-size: 14px;
  }
  .confirmation-label {
    font-size: 24px;
  }
  .product-labels {
    font-size: 24px;
  }
  .product-title {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
  }
  .product-title li {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
  }
}

.checkbox-label {
  font-size: 20px;
  font-weight: 600;
}

.confirmation-label {
  color: #d8472e;
  font-family: 'Bebas Neue', sans-serif;
  margin: 0 0 0 25px;
}

.confirmation-section {
  margin: 2rem 0;
}

.form-list-div {
  width: '80%';
  margin: auto;
}

.ant-picker-calendar {
  width: 290px; /* Adjust this value as needed */
  font-size: 14px; /* Adjust font size */
  /* Other styling adjustments */
  position: absolute;
  right: 100px;
}

@media screen and (max-width: 575px) {
  .ant-picker-dropdown {
    position: absolute;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
  }
}

.ant-menu-title-content {
  display: flex !important;
  justify-content: flex-start !important;
}

.ant-form-item-explain-error {
  display: flex !important;
  justify-content: flex-start !important;
}
